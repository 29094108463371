var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"content"}},[_c('div',{class:{ stickyHeader: _vm.$vuetify.breakpoint.lgAndUp, stickyHeaderSmall: !_vm.$vuetify.breakpoint.lgAndUp }},[_c('v-row',{staticStyle:{"padding":"15px"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"depressed":"","small":"","to":{path: '/agpa/archives/' }}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-chevron-left ")]),_c('span',[_vm._v("Retour")])],1)]}}],null,false,1439477914)},[_c('span',[_vm._v("Retour au sommaire des archives")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","depressed":"","to":{path: '/agpa/archives/' }}},on),[_c('v-icon',[_vm._v("fas fa-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Retour au sommaire des archives")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextYear(-1)}}},on),[_c('v-icon',[_vm._v("fa-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Edition précédente")])]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.year)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.isLoading},on:{"click":function($event){return _vm.gotoNextYear(1)}}},on),[_c('v-icon',[_vm._v("fa-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Edition suivante")])]),_c('v-spacer')],1),(_vm.isLoading)?_c('v-progress-linear',{staticStyle:{"position":"absolute","bottom":"-5px","left":"0","right":"0","height":"5px"},attrs:{"color":"accent","indeterminate":""}}):_vm._e()],1),(_vm.current && _vm.agpaMeta)?_c('div',_vm._l((_vm.current.categoriesOrders),function(catIdx){return _c('v-row',{key:catIdx,staticStyle:{"margin":"15px","margin-top":"50px","flex-wrap":"nowrap"}},[_c('v-card',{staticStyle:{"margin":"15px","width":"400px","min-width":"400px","display":"relative","padding":"40px 0 10px 0"}},[_c('img',{staticStyle:{"position":"absolute","top":"-50px","left":"150px"},attrs:{"src":("/img/agpa/cupesMaxi/c" + catIdx + ".png"),"width":"100px"}}),_c('v-row',{staticStyle:{"padding":"5px 15px","margin":"0","background":"#efefef","border":"1px solid #ddd","border-width":"1px 0"}},[_c('span',{staticStyle:{"font-family":"'Tangerine', serif","font-size":"2em"}},[_vm._v(" "+_vm._s(_vm.current.categories[catIdx].title)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"line-height":"48px"}},on),[_c('i',{staticClass:"far fa-user"}),_vm._v(" "+_vm._s(_vm.current.categories[catIdx].totalUsers))])]}}],null,true)},[_c('v-spacer'),_c('span',[_vm._v("Nombre total de participants")])],1),_vm._v("     "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"line-height":"48px"}},on),[_c('i',{staticClass:"far fa-image"}),_vm._v(" "+_vm._s(_vm.current.categories[catIdx].totalPhotos))])]}}],null,true)},[_c('v-spacer'),_c('span',[_vm._v("Nombre total de photos")])],1)],1),_c('v-list',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.current.categories[catIdx].photos),function(p){return _c('tr',{key:p.id},[_c('td',{staticStyle:{"text-align":"left"}},_vm._l((p.awards),function(a){return _c('span',{key:a},[(a == 'diamond')?_c('i',{staticClass:"fas fa-circle",staticStyle:{"color":"#c3f1ff"}}):_vm._e(),(a == 'gold')?_c('i',{staticClass:"fas fa-circle",staticStyle:{"color":"#c68b00"}}):_vm._e(),(a == 'sylver')?_c('i',{staticClass:"fas fa-circle",staticStyle:{"color":"#9b9b9b"}}):_vm._e(),(a == 'bronze')?_c('i',{staticClass:"fas fa-circle",staticStyle:{"color":"#964c31"}}):_vm._e(),(a == 'nominated')?_c('i',{staticClass:"far fa-circle"}):_vm._e(),(a == 'honor')?_c('i',{staticClass:"far fa-smile"}):_vm._e()])}),0),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(p.user.username)+" ")]),_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(p.title)+" ")])])}),0)]},proxy:true}],null,true)})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"20px"},attrs:{"depressed":"","to":{path: ("/agpa/archives/" + _vm.year + "/" + catIdx) }}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" far fa-images ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Galerie")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Voir les photos de la catégorie "+_vm._s(_vm.current.categories[catIdx].title)+" ")])])],1),_c('div',{staticStyle:{"overflow":"hidden","display":"flex","width":"100%"}},_vm._l((_vm.current.categories[catIdx].photos),function(photo){return _c('div',{key:photo.id,staticStyle:{"display":"inline-block","width":"250px","height":"250px","margin":"auto"}},[_c('div',{staticStyle:{"width":"250px","height":"250px","display":"table-cell","text-align":"center","vertical-align":"middle"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"thumb",attrs:{"src":photo.thumb},on:{"click":function($event){return _vm.photosGalleryDisplay(photo.idx)}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(photo.username)+" - "+_vm._s(photo.title))])])],1)])}),0)],1)}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }